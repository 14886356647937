<template>
  <div class="elv-report-sources-container">
    <p class="elv-report-sources-title">{{ t('project.nav.sources') }}</p>
    <div class="elv-report-sources-header">
      <el-tabs v-model="tabActiveName" class="elv-report-sources-tab" @tab-click="onChangeTab">
        <el-tab-pane :label="t('project.nav.sources')" name="reports-sources-detail" class="elv-report-detail-pane">
        </el-tab-pane>
        <el-tab-pane :label="t('project.nav.accounts')" name="reports-sources-accounts" class="elv-report-detail-pane">
        </el-tab-pane>
      </el-tabs>

      <div class="elv-reports-source-operating">
        <div v-if="showBatchEditButton" class="elv-reports-contacts-functional__edit" @click="onBatchEdit">
          <SvgIcon name="reports-batch-edit" width="16" height="16" />
          {{ t('button.edit') }}({{ reportStore.batchEditSelected.length }})
        </div>
      </div>
    </div>
    <router-view></router-view>

    <AccountOrContactDrawer
      ref="overlayDrawerRef"
      :is-batch="true"
      drawer-title="Contact"
      type="contacts"
      :total-contacts="reportStore.batchEditSelected.length"
      :drawer-data="{}"
    />
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useReportStore } from '@/stores/modules/reports/index'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import AccountOrContactDrawer from '../components/AccountOrContactDrawer.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()
const menuGlobalStore = useMenuGlobalStore()

const overlayDrawerRef = ref()

const tabActiveName = ref('reports-sources-detail')

// eslint-disable-next-line no-unused-vars
const headerWidth = computed(() => {
  return utils.isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const showBatchEditButton = computed(() => {
  return route.name === 'reports-contacts-named' || route.name === 'reports-contacts-unnamed'
})

const onChangeTab = (tab: any) => {
  reportStore.batchEditSelected = []
  if (tab.props.name === 'reports-rules' && !currentEntityPermission.value?.configRule?.view) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (tab.props.name === 'reports-sources-detail' && !currentEntityPermission.value?.dataSource?.view) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (tab.props.name === 'reports-sources-accounts' && !currentEntityPermission.value?.entityAccount?.view) {
    ElMessage.warning(t('message.noPermission'))
    return
  }

  router.push({
    name: tab.props.name
  })
}

const onBatchEdit = () => {
  if (!currentEntityPermission.value?.contact?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (reportStore.batchEditSelected.length) overlayDrawerRef.value?.onCheckDrawerStatus()
}

onMounted(() => {
  if (route.name === 'reports-contacts-named' || route.name === 'reports-contacts-unnamed') {
    tabActiveName.value = 'reports-contacts'
  } else {
    tabActiveName.value = String(route.name)
  }
})
</script>

<style lang="scss" scoped>
:deep(.elv-confirm-info-header__title) {
  text-align: center;
}

.elv-report-sources-header {
  position: relative;
}

.elv-reports-source-operating {
  position: absolute;
  bottom: 4px;
  right: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;

  .elv-reports-contacts-functional__edit {
    float: right;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    height: 32px;
    border: 1px solid #dde1e6;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;

    svg {
      fill: #1e2024;
      margin-right: 8px;
    }

    &:hover {
      border: 1px solid #7596eb;
      color: #1343bf;

      svg {
        fill: #1343bf;
      }
    }
  }
}

.elv-report-sources-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #000000;
  margin-left: 20px;
  margin-top: 16px;
}

:deep(.elv-report-sources-tab) {
  .el-tabs__header {
    width: v-bind('headerWidth');
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .el-tabs__nav-wrap:after {
    height: 1px !important;
  }

  .el-tabs__nav.is-top {
    padding-left: 20px !important;
  }

  .el-tabs__active-bar {
    background-color: $elv-color-1343BF;
  }

  .el-tabs__item.is-active {
    color: $elv-color-394048;
  }

  .el-tabs__item {
    color: $elv-color-858B92;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    padding: 0;
    padding-left: 24px;
  }
}
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
